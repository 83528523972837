<template>
  <div class="grid-x">
    <div class="cell small-12 medium-8 bg-grey pb-5">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-10 medium-offset-1">
          <div class="headline-box">
            <h1 class="text-center text-white">
              Budget<br />
              Impact<br />
              Model
            </h1>
          </div>
        </div>
        <div class="cell small-12 medium-10 medium-offset-1 pt-5 pb-5">
          <span
            @click="start"
            v-if="hashVerified === true"
            class="active-link">Get started</span>
          <span v-else class="inactive-link">This link is not yet active</span>
        </div>
        <div class="cell small-12 medium-10 medium-offset-1 pt-10">
          <p class="text-ae white-border text-white text-center pa-3 mb-0">
            Adverse events should be reported.<br />
            UK: Reporting forms and information can be found at https://yellowcard.mhra.gov.uk/.<br />
            Adverse events should also be reported to Biogen Idec Limited.<br />
            Tel: +44(0)20 360 886 22. Email: medinfo.europe@biogen.com
          </p>
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-5">
        <div class="cell small-12 medium-5 medium-offset-1 mb pt-5">
          <p class="text-white pi-info">
            Prescribing information can be found via the PI<br />
            button in the top right hand corner of every page.
          </p>
        </div>
        <div class="cell small-12 medium-5 mb pt-5">
          <p class="text-small text-white text-right">
            Biogen-192038. Date of preparation: April 2023
          </p>
        </div>
      </div>
    </div>
    <div class="cell small-12 medium-4">
      <div :style="{ height: viewportHeight + 'px' }" class="relative">
        <img class="top-logo" src="../assets/logos/Biogen_logo.png" alt="" />
        <div v-if="hashVerified" class="rhs-content">
          <p class="text-medium text-right">
            This model has been developed by Biogen to allow you to compare the costs associated
            with different ranibizumab brands. Please be advised that the figures which come
            pre-populated in the tool are intended to act as a guide only and can be changed by
            the user. The output generated by the tool is the result of deterministic calculations
            and should be considered as computer generated forecast guidance only. As with any
            forecast model, the accuracy and validity of the output is reliant on accurate and
            valid input data. It is the responsibility of all users of this document to ensure
            that input data is as factually accurate as possible. The final cost implication for
            the health-system is modelled on assumption input and is subject to change based on
            different input assumptions.
          </p>
          <p class="text-medium text-right">
            All information contained in this model is confidential, for the information of the
            intended recipient only, and must not be used, published or redistributed. In
            particular, any discounts remain confidential and must not be shared with any third
            party.
          </p>
        </div>
        <div class="text-center">
          <img class="bottom-logo" width="300px" src="../assets/logos/Byooviz_logo.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios';

export default {
  name: 'Links',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      hashVerified: false,
      links: [],
      viewportHeight: 0,
    };
  },
  methods: {
    start() {
      this.$router.push('/start');
    },
    checkHash() {
      if (this.$route.params.hash && this.$route.params.id) {
        const postData = {};
        postData.hash = this.$route.params.hash;
        postData.linkId = this.$route.params.id;
        axios.post('/links/checkHash.json', postData)
          .then((response) => {
            this.hashVerified = response.data.result;
            this.$store.commit('setHash', this.$route.params.hash);
            this.$store.commit('setId', this.$route.params.id);
            this.$cookies.set('hash', this.$route.params.hash);
          });
      } else {
        this.$router.push('/inactive-link');
      }
    },
  },
  mounted() {
    this.checkHash();
    this.viewportHeight = window.innerHeight;
  },
};
</script>
